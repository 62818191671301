import React, { useState, useMemo } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  FormGroup,
  Input,
} from "reactstrap";
import _ from "lodash";
import moment from "moment-timezone";
import Loader from "react-loaders";
import { useDispatch, useSelector } from 'react-redux';
import service from "../../../utils/service";
import Activity from "./Activity";
import Attachment from "./Attachment";
import { showModal } from '../../../actions/modalActions/modalActions';
const ActivityList = React.memo(({ CommentsData }) => {
  return (
    <div className="height-300 border-top">
      {_.isArray(CommentsData) &&
        CommentsData.slice().map((item) => (
          <div className="mt-3" key={item.id}>
            <Activity
              name={item?.owner?.fullname || "No Name"}
              date={
                item?.date
                  ? moment.utc(item.date, "YYYY-MM-DD HH:mm:ss.SSSSSS")
                    .tz("Asia/Kolkata")
                    .format("DD MMM YYYY, hh:mm a")
                  : "---"
              }
              description={item?.comment || ""}
            />
          </div>
        ))}
    </div>
  );
});

const Comments = (props) => {
  const { CommentsData, cb, case_id, is_editable, case_status, Text } = props;
  const [comment, setComment] = useState("");
  const [poststatus, setPostStatus] = useState("");
  const [attachmentUI, setAttachmentUI] = useState(false);
  const isVisible = useSelector((state) => state.my_profile.my_org.status);
  console.log(isVisible,"Comments")
  const status = isVisible === "active" ? true : false
  const dispatch = useDispatch()
  const trigger_API = async () => {
    setPostStatus("loading");
    let API_URL = `v1/cases/${case_id}`;
    let post_info = {
      comments: comment,
    };

    try {
      let res = await service("patch", API_URL, { data: post_info });
      let success = _.get(res, "success");
      if (success) {
        setComment("");
        cb();
        setPostStatus("done");
      }
    } catch (error) {
      console.error("Error while updating case:", error);
    } finally {
      setPostStatus("done");
    }
  };


  const handleInputChange = (e) => {
    setComment(e.target.value);


  };

  const getButtonUI = () => {
    return (
      <div className="ms-auto">
        {Text !== "Off-Canvas" && is_editable && (
          <button
            type="button"
            className="btn active me-2"
            data-bs-toggle="button"
            aria-pressed="true"
            onClick={() => setAttachmentUI(!attachmentUI)}
          >
            <i className="attachments" />
          </button>
        )}

        <button
          className="btn btn-primary"
          onClick={() => {
            status ?
            trigger_API():dispatch(showModal())
          }}
          disabled={comment.trim().length > 0 ? false : true}
        >
          Post
        </button>
      </div>
    );
  };

  const getBodyUI = () => {
    return (
      <div>
        <CardBody className="p-2">
          <CardSubtitle>
            {case_status === "closed" ? null : <FormGroup className="p-0">
              <Input
                type="textarea"
                placeholder="Add a Comment"
                value={comment}
                onChange={handleInputChange}

              />

              <div className="d-flex pt-2">
                {poststatus === "loading" ? (
                  <div className="text-center">
                    <Loader type="line-scale" />
                  </div>
                ) : (
                  getButtonUI()
                )}
              </div>

            </FormGroup>}
            {attachmentUI && <Attachment is_editable={is_editable} cb={cb} />}
            {CommentsData && CommentsData.length > 0 ? (
              <ActivityList CommentsData={CommentsData} />
            ) : (
              "No Comments Added"
            )}
          </CardSubtitle>
        </CardBody>
      </div>
    );
  };

  return <div>{getBodyUI()}</div>;
};

export default Comments;

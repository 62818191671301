import React, { useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import Dropzone from "react-dropzone";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import service from "../../../utils/service";
import { showModal } from '../../../actions/modalActions/modalActions';
const Attachments = (props) => {
  const { attachments, is_editable, match, cb } = props;
  const case_id = _.get(match, "params.case_id", "");
  const [convertedData, setConvertedData] = useState({});
  const file = _.get(convertedData, "file");
  const fileName = _.get(convertedData, "fileName", "");
  const isVisible = useSelector((state) => state.my_profile.my_org.status);
  const status = isVisible === "active" ? true : false
  const dispatch = useDispatch()
  const onDrop = (acceptedFiles) => {
    let reader = new FileReader();
    acceptedFiles.map((file) => {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setConvertedData({
          file: reader.result,
          fileName: file.name,
        });
      };
    });
  };

  const onCancel = () => {
    setConvertedData({});
  };

  const trgger_Download_API = async (attachment_id) => {
    let API_URL = `v1/cases/${case_id}/download_attachment?attachment_id=${attachment_id}`;
    let res = await service("get", API_URL);
    let storage_type = _.get(res, "meta.Storage_type");
    let f_data = _.get(res, "data", "");
    let fileType = _.get(res, "type");
    let cType = "";
    if (storage_type === "s3") {
      window.open(f_data, '_blank');
    } else {
      if (f_data) {
        if (fileType == "doc") {
          cType = "data:application/msword;base64,";
        } else if (fileType === "docx") {
          cType =
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
        } else if (fileType === "xlsx") {
          cType =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        } else if (fileType === "xls") {
          cType =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        } else if (fileType === "pdf") {
          cType = "data:application/pdf;base64,";
        } else {
          cType = `data:image/${fileType};base64,`;
        }
        if (cType) {
          f_data = cType + f_data;
          const link = document.createElement("a");
          link.href = f_data;
          link.setAttribute("download", res.name);
          link.setAttribute("target", "_blank");
          link.click();
        }
      }
    }
  };

  const getAttachments = () => {
    return (
      <div className="d-flex flex-wrap">
        {_.isArray(attachments) &&
          attachments.map((attachment) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="td-width">{attachment.name}</div>
                      <i
                        className="fa fa-download cursor-pointer fs-24"
                        onClick={() => {
                          trgger_Download_API(_.get(attachment, "_id"));
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const trigger_Upload = async () => {
    let API_URL = `v1/cases/${case_id}`;
    let post_info = {
      attachment: file,
      file_name: fileName,
    };
    let res = await service("patch", API_URL, { data: post_info });
    let success = _.get(res, "success");
    if (success) {
      cb();
      setConvertedData({});
    }
  };

  return (
    <>
      {getAttachments()}
      {is_editable && (

        <div className="row">
          {file && fileName && (
            <div className="mt-3 d-flex justify-content-center flex-column">
              <b className="mb-2 d-block">Dropped Files</b>
              <ListGroup>
                <ListGroupItem>{fileName}</ListGroupItem>
              </ListGroup>
              <div>
                <button
                  className="btn btn-primary mt-2 me-3"
                  onClick={() => {
                    status ?
                      trigger_Upload():dispatch(showModal())
                  }}
                >
                  Upload
                </button>
                <button
                  className="btn btn-primary mt-2 me-3"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
          <div className="col-md-6 offset-md-3 mb-3">
            <div className="dropzone-wrapper dropzone-wrapper-lg rw-list">
              <Dropzone onDrop={onDrop} onFileDialogCancel={onCancel}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps({
                        accept:
                          ".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .csv",
                        maxSize: 20 * 1024 * 1024,
                      })}
                    />
                    <div className="dropzone-content">
                      <p>
                        Try dropping some files here, or click to select files
                        to upload.
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(Attachments);

import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../actions/modalActions/modalActions';

const MyModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.modal.isVisible);

  const toggle = () => {
    dispatch(hideModal());
  };

  return (
    <Modal isOpen={isVisible} toggle={toggle} className="styled-modal">
      <ModalHeader className="no-border-header custom-header" toggle={toggle}>
        Account Suspended
      </ModalHeader>
      <ModalBody>
        Your account has been suspended. Please contact customer support for assistance.
      </ModalBody>
      <ModalFooter className="no-border-footer">
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MyModal;

import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select-new";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { userApi } from '../../../../../actions/myprofile/action';
import service from "../../../../../utils/service";
import { showModal } from '../../../../../actions/modalActions/modalActions';
import { useDispatch, useSelector } from "react-redux";
const Preferences = (props) => {
  const [selectedOption, setSelectedOption] = useState([]);

  let user_id = _.get(props, "profileData.user_id", "");
  let interests = _.get(props, "profileData.interests", []);
  const isVisible = useSelector((state) => state.my_profile.my_org.status);
  const status = isVisible === "active" ? true : false;
  const dispatch = useDispatch();
  const options = [
    { value: "industryAnalysis", label: "Industry Analysis" },
    { value: "marketMapping", label: "Market Mapping" },
    { value: "creditUnderWriting", label: "Credit Underwriting" },
    { value: "peerReview", label: "Peer Review" },
  ];

  useEffect(() => {
    if (
      interests
    ) {
      getUserData();
    }
  }, [interests]);

  const getUserData = async () => {
    let set_arr = [];
    if (options && _.isArray(options)) {
      options.forEach((a) => {
        if (interests.includes(a.label)) {
          set_arr.push(a);
        }
      });
    }
    setSelectedOption(set_arr);
  };

  const handleChange = (value) => {
    let preferencesList = [];

    if (value && _.isArray(value)) {
      value.forEach((a) => {
        preferencesList.push(a.label);
      });
    }
    if(status){
    saveInterestsAPI(preferencesList);
    }
    else{
      dispatch(showModal());
    }
  };

  const saveInterestsAPI = async (preferencesList) => {
    let data = {
      interests: preferencesList,
    };
    let res = await service("patch", "/v1/account/user_interests", { data });

    if (_.get(res, "message") === "interests updated successfully.") {
      props.userApi(user_id)
    }
  };

  return (
    <>
      <div className="fs-18 fc-blue fw-600">
        Help us get to know you better
      </div>
      <div className="fs-12 fw-400 fc-black">
        What do you use Crediwatch for?
      </div>
      <FormGroup>
        <Select
          className="mt-2"
          closeMenuOnSelect={false}
          isMulti
          options={options}
          onChange={handleChange}
          value={selectedOption}
        />
      </FormGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  profileData: _.get(state, "my_profile.profileData"),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userApi
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);

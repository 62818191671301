import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import _ from "lodash";
import { connect } from "react-redux";

import SearchForm from "../../../components/search_form";
import allPaths from "../../../route/allPaths";
import detect_mobile from '../../../utils/detect_mobile'; 
import { Mixpanel } from "../../../utils/mixpanel";

const SearchBox = (props) => {
  const { openInput, showConfirmBox, setEnableSearchBarOnHeader } = props;
  const [searchText, setSearchText] = useState("");

  let history = _.get(props, "history");
  const queryParams = new URLSearchParams(_.get(history, "location.search"));
  const queryObj = {
    q: queryParams.get("q") || "",
  };
  useEffect(() => {
    setSearchText(queryObj.q);
  }, [queryObj.q]);

  const is_PARTY_PAGE = _.get(props, "party_meta");
  const SearchBar = _.get(props,"searchbar");
  const classNameToDisplayInputBox = () => {
    const is_mobile = detect_mobile()
    if (openInput && is_mobile) {
      return "d-block";
    } else if(is_PARTY_PAGE && !is_mobile || SearchBar && !is_mobile) {
      return "d-block";
    } else {
      return "d-none";
    }
  };
  
  const pushURL = (url) => {
    const { history } = props;
    history && history.push(url);
  };

  const selectOption = (party_id, suggestion) => {
    if (party_id) {
      let URL = `${allPaths.PARTY_ROOT_ROUTE}/${party_id}`;
      Mixpanel.track("search_party", {
        search_text: searchText,
        autoload_selection: true,
        party_name: suggestion?.name,
      });
      pushURL(URL);
    }
  };

  const submitInput = (searchText) => {
    if (searchText && _.isString(searchText) && searchText.length >= 3) {
      let URL = `${allPaths.SEARCH_ROUTE}?q=${searchText}`;
      Mixpanel.track("search_party", {
        search_text: searchText,
        autoload_selection: false,
      });
      pushURL(URL);
    }
  };

  return (
    <Fragment>
      <div
        className={`${classNameToDisplayInputBox()}`}
      >
        <div className={cx("ml-auto me-auto ", { active: true })}>
          {showConfirmBox ? (
            <SearchForm
              selectOption={selectOption}
              showConfirmBox={showConfirmBox}
              setEnableSearchBarOnHeader={setEnableSearchBarOnHeader}
              searchText={searchText}
            />
          ) : (
            <div data-tut="dashboard-searchbar">
              <SearchForm
                selectOption={selectOption}
                submitInput={submitInput}
                openInput={openInput}
                setEnableSearchBarOnHeader={setEnableSearchBarOnHeader}
                searchText={searchText}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  my_org: _.get(state, "my_profile.my_org"),
  party_meta: _.get(state, "party.information.result.meta"),
  searchbar:_.get(state,"globalReducer.searchbar")
});

export default connect(mapStateToProps, null)(withRouter(SearchBox));

import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../../actions/modalActions/modalActions";
import _ from "lodash";
import service from "../../../utils/service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Mixpanel } from "../../../utils/mixpanel";

class WatchlistButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWatchlisted: {
        status: "not-triggered",
        result: false,
      },
    };
  }

  componentDidMount() {
    this.isWatchlistedAPI();
  }

  isWatchlistedAPI = async () => {
    const { party_id } = this.props;

    this.setState({
      isWatchlisted: {
        status: "loading",
        result: false,
      },
    });

    try {
      const res = await service("get", `/v1/admin/parties/${party_id}/is_watchlisted`);
      this.setState({
        isWatchlisted: {
          status: "done",
          result: _.get(res, "meta.total_records", 0) > 0,
        },
      });
    } catch (error) {
      console.error("Error fetching watchlist status:", error);
      toast.error("Failed to fetch watchlist status");
      this.setState({
        isWatchlisted: {
          status: "error",
          result: false,
        },
      });
    }
  };

  addRemoveWatchlist = async (action) => {
    const { cb, list_id, party_id, partyname, isVisible, dispatch } = this.props;

    if (isVisible === "active") {
      const data = {
        list: {
          action: action,
          list_type: "watchlist",
          parties: [
            {
              party_id: party_id,
              name: partyname,
            },
          ],
        },
      };

      this.setState({
        isWatchlisted: {
          status: "loading",
          result: false,
        },
      });

      try {
        const res = await service("patch", `/v1/admin/lists/${list_id}`, { data });
        if (res) {
          if (action === "add") {
            if (partyname) {
              Mixpanel.track("add_to_watchlist", { party_name: partyname });
            }
            toast.success("Successfully added to your Watchlist");
          } else {
            if (partyname) {
              Mixpanel.track("remove_from_watchlist", { party_name: partyname });
            }
            toast.error("Party removed from your Watchlist");
          }
          this.isWatchlistedAPI();
          cb && cb();
        }
      } catch (error) {
        console.error("Error updating watchlist:", error);
        toast.error("Failed to update watchlist");
      }
    } else {
      // Dispatch Redux action when status is false
      dispatch(showModal());
    }
  };

  getIconUI = (disableIconFunc) => {
    const { isWatchlisted } = this.state;

    return (
      <>
        {isWatchlisted.status === "done" && isWatchlisted.result ? (
          <div
            onClick={() =>
              !disableIconFunc &&
              !this.props.isButtonBased &&
              this.addRemoveWatchlist("remove")
            }
          >
            <img
              src="https://cdn.crediwatch.com/assets/icons/product/un_watchlist.svg"
              alt="Unwatchlist"
              width={this.props.isButtonBased ? "14px" : "auto"}
            />
          </div>
        ) : (
          <div
            onClick={() =>
              !disableIconFunc &&
              !this.props.isButtonBased &&
              this.addRemoveWatchlist("add")
            }
          >
            <img
              src="https://cdn.crediwatch.com/assets/icons/product/watchlist_icon.svg"
              alt="Watchlist"
              width={this.props.isButtonBased ? "14px" : "auto"}
            />
          </div>
        )}
      </>
    );
  };

  buttonClick = () => {
    const { isWatchlisted } = this.state;

    if (isWatchlisted.status === "done") {
      if (isWatchlisted.result) {
        this.addRemoveWatchlist("remove");
      } else {
        this.addRemoveWatchlist("add");
      }
    }
  };

  render() {
    return (
      <div className="cursor-pointer">
        {this.props.isButtonBased ? (
          <button
            className="btn-transition btn btn-outline-primary d-flex align-items-center"
            onClick={this.buttonClick}
            disabled={this.state.isWatchlisted.status !== "done"}
          >
            {this.state.isWatchlisted.status !== "done" ? (
              <span>Loading...</span>
            ) : (
              <>
                <span className="me-2 fs-14 d-md-inline d-none">Watchlist</span>
                {this.getIconUI()}
              </>
            )}
          </button>
        ) : (
          this.getIconUI()
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    list_id: _.get(state.my_profile.my_watclist, "result.list_id"),
    isVisible: state.my_profile.my_org.status,
  };
}

export default connect(mapStateToProps)(WatchlistButton);

import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import _ from "lodash";
import { withRouter } from "react-router-dom";

import service from "../utils/service";
class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: [],
      id: "",
    };
    this.callSearchAPI = _.debounce(this.apiResult.bind(this), 500);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const location = _.get(this.props, "location");
    const queryParams = new URLSearchParams(location.search);
    const queryName = queryParams.get("q");
    if (this.state.value === '' && queryName !== null) {
      this.setState({ value: queryName })
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { searchText, location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      if (location.pathname === "/specific-path") {
        this.setState({ value: "Some default value based on path" });
      } else {
        this.setState({ value: "" });
      }
    }
    if (prevProps.searchText !== searchText) {
      this.setState({ value: searchText || "" });
    }
  }
  
  

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { openInput, setEnableSearchBarOnHeader } = this.props;
    if (openInput && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      document.getElementById('fabInput') && document.getElementById('fabInput').blur();
      setEnableSearchBarOnHeader && setEnableSearchBarOnHeader(true)
    }
  }

  apiResult = (value) => {
    this.setState(
      {
        suggestions: [{ name: "Loading...", type: "info" }],
      },
      () => {
        let API_URL = '/v1/search/parties/complete';
        let params = {
          q: value,
          m: "autocomplete",
        };

        service("get", API_URL, { params: params }).then((response) => {
          let data = _.get(response, "data", []);
          if (_.isArray(data)) {
            if (data.length > 0) {
              const records = _.chain(data).slice(0, 10).value();
              this.setState({
                suggestions: records,
              });
            } else {
              this.setState({
                suggestions: [{ name: "No Records Found", type: "info" }],
              });
            }
          } else {
            this.setState({
              suggestions: [{ name: "Error Occured", type: "info" }],
            });
          }
        });
      }
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 2) {
      this.callSearchAPI(value);
    } else {
      this.setState({
        suggestions: [{ name: "Minimum 3 characters required", type: "info" }],
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleSuggestion = (suggestion) => {
    return (
      _.get(suggestion, "attributes.name", "") || _.get(suggestion, "name", "")
    );
  };

  getSuggestionValue = (suggestion) => {
    return suggestion && suggestion.name
      ? suggestion.name
      : this.handleSuggestion(suggestion);
  };

  getIconClasses = (model_type, isListed) => {
    let className = ""
    if (model_type === "public_company") {
      className = "icon-public-listed-company"
    } else if (model_type === "private_company") {
      className = "icon-private-company"
    } else if (model_type === "person") {
      className = "icon-one-person-company"
    } else if (model_type === "limited_liability_partnership") {
      className = "icon-limited-liability-partnership"
    } else if (model_type === "firm") {
      className = "icon-firm"
    } else {
      className = "lnr-apartment"
    }
    return className
  }

  renderSuggestion = (suggestion) => {
    if (suggestion.type === "info") {
      return <div className="d-flex">{_.get(suggestion, "name", "")}</div>;
    } else {
      let name =
        _.get(suggestion, "attributes.name", "") ||
        _.get(suggestion, "name", "");
      let type =
        _.get(suggestion, "attributes.model_type", "") ||
        _.get(suggestion, "type", "");
      let model_type = type.split("_").join(" ");

      return (
        <div className="d-flex align-items-center">
          <div className="search-party-icon">
            <i className={this.getIconClasses(_.get(suggestion, "attributes.model_type") || _.get(suggestion, "type", ""))} />
          </div>
          <div className="ml-2">{name.toUpperCase()}</div>
          <div className="ml-4">({model_type.toUpperCase()})</div>
        </div>
      );
    }
  };

  onSuggestionSelected = (e, selectedObj) => {
    e && e.preventDefault && e.preventDefault();
    const { submitInput, selectOption, showConfirmBox } = this.props;
    const { value } = this.state;
    let suggestion = _.get(selectedObj, "suggestion");
    if (showConfirmBox) {
      this.setState(
        {
          value: "",
        },
        () => showConfirmBox(suggestion)
      );
    } else if (suggestion.type !== "info") {
      let party_id =
        _.get(suggestion, "id", "") ||
        _.get(suggestion, "_id", "") ||
        _.get(suggestion, "party_id", "");
      if (party_id) {
        selectOption && selectOption(party_id, suggestion);
      } else {
        submitInput && submitInput(value);
      }
    }
  };

  onSubmit = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { value } = this.state;
    const { submitInput } = this.props;
    submitInput && submitInput(value);
  };

  getSuggestionClassName = () => {
    const { suggestions } = this.state;
    const { searchBoxHeight } = this.props;

    if (_.isArray(suggestions) && suggestions.length > 0) {
      if (searchBoxHeight) {
        return `lg-suggestion-custom-top`
      }
    }
    return ''
  }
  
  handleClose = () => {
    this.setState({
      value: "",
      suggestions: [],
    })
  }

  renderInputComponent = inputProps => {
    let show_close = false;
    const { value } =  this.state;
    if(value && value.length > 2) {
      show_close = true;
    }
    return (
      <div className="position-relative">
        <input {...inputProps} />
        {show_close &&
          <div className="position-absolute w-100 h-100" style={{ top: '0px' }}>
            <div className="d-flex align-items-center w-100 h-100 justify-content-end">
              <i className="fas fa-times me-3 cursor-p" onClick={() => this.handleClose()}></i>
            </div>
          </div>
        }
      </div>
    )
  }

  render() {
    const { value, suggestions } = this.state;
    const { openInput, showConfirmBox, searchBoxHeight } = this.props;

    const inputProps = {
      placeholder: showConfirmBox ? "Search for Business or a Registration" : "Search for a Person, Business or a Registration",
      value,
      style: { height: searchBoxHeight ? `${searchBoxHeight}px` : '' },
      onChange: this.onChange,
      id: openInput ? 'fabInput' : 'searchText'
    };
    let theme = {
      container: 'react-autosuggest__container',
      containerOpen: 'react-autosuggest__container--open',
      input: 'react-autosuggest__input',
      inputOpen: 'react-autosuggest__input--open',
      inputFocused: 'react-autosuggest__input--focused',
      suggestionsContainer: 'react-autosuggest__suggestions-container',
      suggestionsContainerOpen: `react-autosuggest__suggestions-container--open ${this.getSuggestionClassName()}`,
      suggestionsList: 'react-autosuggest__suggestions-list',
      suggestion: 'react-autosuggest__suggestion',
      suggestionFirst: 'react-autosuggest__suggestion--first',
      suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
      sectionContainer: 'react-autosuggest__section-container',
      sectionContainerFirst: 'react-autosuggest__section-container--first',
      sectionTitle: 'react-autosuggest__section-title'
    }
    return (
      <div id="advancedSearch" ref={this.wrapperRef}>
        <form onSubmit={this.onSubmit}>
          <Autosuggest
            suggestions={suggestions}
            inputAttributes={{ className: 'react-autosuggest__suggestions-section' }}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.onSuggestionSelected}
            theme={theme}
            ref={() => { document.getElementById('fabInput') && document.getElementById('fabInput').focus(); }}
          />
        </form>
      </div>
    );
  }
}

export default withRouter(SearchForm);

import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import service from "../../utils/service";

const CustomDropDown = ({ caseId, data, getStatusAndAssignee,InformationPage }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [statusClicked, setStatusClicked] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [case_status_Data, setCaseStatusData] = useState([]);
    const [case_status_code, setCaseStatusCode] = useState({
        code: undefined,
        msg: "",
    });
    const caseStatus = _.get(data, "case_status", "");
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const trigger_getCases_API = async () => {
        let API_URL = `v1/cases/${caseId}/case_status`;
        let res = await service("get", API_URL);
        const statusCode = res?.status ?? 0;
        const message = res?.data?.message ?? "";
        setCaseStatusCode({ code: statusCode, msg: message });
        let data = _.get(res, "ticket", "");
        if (_.isArray(data)) {
            setStatusLoading(true);
            setCaseStatusData(data);
        }
    };

    useEffect(() => {
        if (statusClicked) {
            trigger_getCases_API();
        }
    }, [statusClicked]);

    const getStatusDropdown = () => {
        if (case_status_code.code === 403) {
            return (
                <DropdownMenu
                    className="dropdown-menu-hover-link"
                    style={{ marginLeft: "-130px" }}
                >
                    <div className="p-2">{case_status_code.msg}</div>
                </DropdownMenu>
            );
        }
        return (
            <DropdownMenu
                className="dropdown-menu-hover-link"
                style={{
                    width: "400px",
                    marginLeft: InformationPage === "Off-Canvas" ? "10px" : "-240px",
                }}
            >
                {statusLoading ? (
                    <>
                        {case_status_Data.length > 0 ? (
                            <>
                                <div>
                                    {case_status_Data.length > 1 ? (
                                        <>
                                            <div className="ms-2">
                                                {" "}
                                                {_.get(data, "case_status", "")} to
                                            </div>
                                            {case_status_Data.map((status) => (
                                                <div key={status.status_id} className="d-flex p-2">
                                                    <div>{status.case_status}</div>
                                                    {status[status.case_status].map((ticket) => (
                                                        <div
                                                            key={ticket._id}
                                                            className="ms-auto cursor-p"
                                                            onClick={() =>
                                                                getStatusAndAssignee(
                                                                    status.case_category,
                                                                    status.status_id,
                                                                    ticket._id
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={`/cdn/assets/icons/user-profile.svg`}
                                                                style={{ height: "20px" }}
                                                                className="me-2"
                                                            />
                                                            <span className="text-primary">
                                                                {ticket.name}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        case_status_Data.map((status) => (
                                            <div key={status.status_id} className="p-2">
                                                <div className="d-flex justify-content-between px-3">
                                                    <div>{_.get(data, "case_status", "")}</div>
                                                    {"-->"}
                                                    <div>{status.case_status}</div>
                                                </div>
                                                {status[status.case_status].map((ticket) => (
                                                    <div
                                                        key={ticket._id}
                                                        className="cursor-p mt-2 px-3"
                                                        onClick={() =>
                                                            getStatusAndAssignee(
                                                                status.case_category,
                                                                status.status_id,
                                                                ticket._id
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={`/cdn/assets/icons/user-profile.svg`}
                                                            style={{ height: "20px" }}
                                                            className="me-2"
                                                        />
                                                        <span className="text-primary">{ticket.name}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="p-2">No Status Found</div>
                        )}
                    </>
                ) : (
                    <Skeleton count={5} />
                )}
            </DropdownMenu>
        );
    };


    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle className={`btn-${caseStatus.toLowerCase()} fs-12 text-dark fw-600  d-flex align-items-center`} onClick={() => setStatusClicked(true)}>
                <div className="d-flex align-items-center">
                    <div>{caseStatus.toUpperCase()}</div>
                    <div className="ms-1">
                        <i className="edit" />
                    </div>
                </div>
            </DropdownToggle>
            {getStatusDropdown()}
        </Dropdown>
    );
};

export default CustomDropDown;

import React, { useState, useEffect } from "react";

import FormArr from "../../form-arr";
import service from "../../../utils/service";
import { connect } from "react-redux";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import _ from "lodash";
import Switch from "react-switch";
import { toast } from "react-toastify";
import Loader from "react-loaders";
import { showModal } from '../../../actions/modalActions/modalActions';
import { useDispatch, useSelector } from "react-redux";
const OffCanvasCreateAlert = (props) => {
  const {  partyDetail, cb } = props;
  const [signalInfo, setSignalInfo] = useState([]);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alertLoader, setAlertLoader] = useState("");
  const history = useHistory();
  const partyID = _.get(props, "match.params.party_id", null);
  const isVisible = useSelector((state) => state.my_profile.my_org.status);
  const statusvalue = isVisible === "active" ? true : false;
  const dispatch = useDispatch();
  useEffect(() => {
    triggerSignalsAPI();
  }, []);

  const triggerSignalsAPI = async () => {
    try {
      const res = await service("get", "v1/admin/alerts/signals");
      const data = _.get(res, "data");
      setSignalInfo(data);
    } catch (error) {
      console.error("Error fetching signal info:", error);
    }
  };

  const convertJsonToOptions = () => {
    if (!Array.isArray(signalInfo)) {
      return [];
    }
    return signalInfo.map((code) => ({
      value: `${code.code}&${code.label}&${code.category}`,
      label: `${code.code}: ${code.summary}`,
    }));
  };

  const trigger_postAPI = async (obj) => {
    setAlertLoader("loading");
    let party_id = _.get(obj, "party.party_id") || partyID;
    let party_name = _.get(obj, "party.name") || _.get(obj, "party");
    let date_of_event = _.get(obj, "date_of_event");
    let risk_severity = _.get(obj, "risk_severity.value");
    let signal_info = _.get(obj, "signal_code.value");
    let alert_summary = _.get(obj, "signal_code.label").split(":")[1].trim();
    let alert_detail = _.get(obj, "alert_description");
    let alert_type, signal_code, alert_category;
    if (signal_info) {
      let arr = signal_info.split("&");
      alert_type = arr[1];
      signal_code = arr[0];
      alert_category = arr[2];
    }
    let force_trigger_case = checked;
    if (
      party_name &&
      date_of_event &&
      risk_severity &&
      alert_type &&
      signal_code &&
      alert_category
    ) {
      let payload = {
        party_id: party_id,
        party_name,
        date_of_event: date_of_event,
        risk_severity: risk_severity,
        signal_code: signal_code,
        force_trigger_case: force_trigger_case,
        alert_summary: alert_summary,
        alert_detail: alert_detail,
        alert_category: alert_category,
        alert_type: alert_type,
      };
      if(statusvalue){
      let res = await service("post", "v1/admin/alerts", { data: payload });
      let status = _.get(
        res,
        "data.message",
        "Error occured while creating a Alert"
      );
      if (res.success) {
        toast.success("Alert Created Successfully");
        setAlertLoader("done");
        handleCloseOffcanvas()
        cb && cb();
      } else {
        toast.error(status);
        setAlertLoader("error");
      }}
      else{
        dispatch(showModal());
      }
    }
  };

  const actionButtons = [
    {
      label: "Create",
      class: "btn btn-primary btn-sm",
      type: "submit-form",
      action: trigger_postAPI,
    },
  ];

  const getDefaultValuesAndSearchArr = () => {
    let defaultValueObj = {};
    let searchArr = [];
    defaultValueObj = {
      ...defaultValueObj,
      date_of_event: new Date().toISOString().split("T")[0],
    };
    if (partyDetail) {
      defaultValueObj = {
        ...defaultValueObj,
        party: _.get(partyDetail, "name"),
      };
      searchArr.push({
        label: "Party Name",
        key: "party",
        mandatory: true,
        disabled: true,
        component: "input",
      });
    } else {
      searchArr.push({
        label: "Party Name",
        key: "party",
        mandatory: true,
        component: "search",
      });
    }

    return { defaultValueObj, searchArr };
  };

  const { defaultValueObj, searchArr } = getDefaultValuesAndSearchArr();

  const formArr = [
    {
      label: "Select Signal/Summary",
      key: "signal_code",
      component: "dropdown",
      mandatory: true,
      option: convertJsonToOptions(),
    },
    {
      label: "Risk Severity ",
      key: "risk_severity",
      component: "dropdown",
      mandatory: true,
      option: [
        {
          value: "high",
          label: "High",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "low",
          label: "Low",
        },
      ],
    },
    {
      label: "Alert Description",
      key: "alert_description",
      type: "text",
      component: "input",
      mandatory: true,
    },

    {
      label: "Date Of Event",
      key: "date_of_event",
      component: "date",
      mandatory: true,
      max: new Date().toISOString().split("T")[0],
    },
  ];

  const handleChange = (checked) => {
    setChecked(checked);
  };
  const get_recurring_case_ui = () => {
    return (
      <div className="row mt-3">
        <label className="col-md-5 col-12 col-form-label ">
          Force Trigger Case
        </label>
        <div className="col-md-7 col-12 mt-2">
          <Switch checked={checked} onChange={handleChange} />
        </div>
      </div>
    );
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offCanvas = queryParams.get("offCanvas");
  useEffect(() => {
    if (offCanvas) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [offCanvas]);
  const handleCloseOffcanvas = () => {
    queryParams.delete("offCanvas");
    history.push({ search: queryParams.toString() });
  };
  return (
    <div className={`offcanvas offcanvas-end ${show ? "show" : ""}`}>
      <div className="offcanvas-header">
        <i className="create-case me-2" />
        <h5 className="offcanvas-title" id="offcanvasRightLabel">
          Create Alert
        </h5>

        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={handleCloseOffcanvas}
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="p-3 mobile-only-scroll">
          {alertLoader === "loading" ? (
            <div className="text-center">
              <Loader type="line-scale" />
            </div>
          ) : (
            <FormArr
              form_fields={[...searchArr, ...formArr]}
              defaultValueObj={defaultValueObj}
              action_buttons={actionButtons}
            >
              {get_recurring_case_ui()}
            </FormArr>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  partyDetail: _.get(state, "party.information.result.data"),
});

export default connect(mapStateToProps)(withRouter(OffCanvasCreateAlert));

import _ from "lodash";
import React, { useState } from "react";
import { FormGroup, Input } from "reactstrap";
import validator from "validator";
import { connect } from "react-redux";
import SweetAlert from "sweetalert-react";

import service from "../../../../../utils/service";
import { Mixpanel } from "../../../../../utils/mixpanel";
import { showModal } from '../../../../../actions/modalActions/modalActions';
import { useDispatch, useSelector } from "react-redux";
function InviteComponent(props) {
  const [emails, setEmail] = useState([
    {
      value: "",
    },
  ]);

  const [show, setShow] = useState();
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [Err, setErr] = useState([]);
  const isVisible = useSelector((state) => state.my_profile.my_org.status);
  const status = isVisible === "active" ? true : false;
  const dispatch = useDispatch();
  const userEmail = _.get(props.profileData, "email");

  const addBox = () => {
    let dupEmail = [
      ...emails,
      {
        value: "",
      },
    ];

    emails.length < 5 && setEmail(dupEmail);
  };

  const removeBox = (index) => {
    let dupEmail = [...emails];
    dupEmail.splice(index, 1);
    setEmail(dupEmail);
    let filterErr = Err.filter((item) => item !== index);
    setErr(filterErr);
  };

  const handleOnchange = (e, index) => {
    let dupEmail = [...emails];
    dupEmail[index].value = e.target.value;
    if (validator.isEmail(e.target.value)) {
      if (e.target.value === userEmail) {
        dupEmail[index].error = 'This email and user email are same'
      } else {
        dupEmail[index].error = false
      }
    } else {
      dupEmail[index].error = 'Please enter a valid email.'
    }

    setEmail(dupEmail);
  };

  const triggerMixPanelEvent = (arr) => {
    setShow(true);
    Mixpanel.track("invite_users", { "page_name": "Dashboard Page" });
    Mixpanel.track("invite_list", { "invite_list": arr })
  };

  const handleSubmit = async () => {
    let arr = [];
    {
      emails.forEach((item, id) => {
        !item.error && arr.push(item.value);
      });
    }

    if (arr.length > 0) {
      setNumberOfUsers(arr.length);
      let res = await service("patch", "/v1/account/invite_users", {
        data: {
          emails: arr,
        },
      });
      {
        res.message === "users invited successfully."
          ? triggerMixPanelEvent(arr)
          : setShow(false);
      }
    }
  };

  const InviteBox = (text, index) => {
    return (
      <FormGroup>
        <Input
          value={text}
          className="fs-14"
          type="email"
          name="email"
          id="exampleEmail11"
          placeholder="Enter Email ID to send Invite"
          onChange={(e) => handleOnchange(e, index)}
        />
      </FormGroup>
    );
  };

  const handleClear = () => {
    setEmail([
      {
        value: "",
      },
    ]);
  };
  const is_invite_enabled_func = () => {
    let bool_enable = false;
    if (_.isArray(emails)) {
      emails.forEach((item) => {
        if (validator.isEmail(item.value) && !item.error) {
          bool_enable = true
        }
      })
    }
    return bool_enable
  };

  const getEmailUI = () => {
    let arr = [];
    if (_.isArray(emails)) {
      emails.forEach((text, index) => {
        arr.push(
          <>
            <div key={index} className="d-flex justify-content-between flex-md-column align-items-center mt-2">
              <div className="w-100 me-3">{InviteBox(text.value, index)}</div>
              {index === 0 ? (
                <div className="d-inline-block">
                  <button
                    className="cw-btn cw-btn-secondary icon-padding"
                    onClick={() => {
                      addBox();
                    }}
                    disabled={emails.length >= 5}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              ) : (
                <div className="d-inline-block">
                  <button
                    className="cw-btn cw-btn-secondary icon-padding">
                    <i className="fas fa-times"
                      onClick={() => removeBox(index)}
                    >

                    </i>
                  </button>
                </div>

              )}
            </div>
            {text.error !== "" && (
              <div
                className="fs-12 fw-400 fs-red form-group"
                style={{ color: "red", fontSize: "12px", fontWeight: 400 }}
              >
                {text.error}
              </div>
            )}
          </>
        );
      });
    }
    return arr;
  };
  const confirmBox = () => {
    setShow(false);
    setEmail([
      {
        value: "",
        isValidEmail: true,
      },
    ]);
  };

  return (
    <>
      <div>
        <SweetAlert
          title={
            numberOfUsers.length > 1
              ? "Users invited successfully!"
              : "User invited successfully!"
          }
          confirmButtonColor=""
          showConfirmButton={true}
          show={show}
          text=""
          type="success"
          onConfirm={() => confirmBox()}
        />
      </div>
      <div className="fs-14 fw-500 fc-blue" >
        <div className=" mb-3">
          <div>
            <div>
              <div className="fs-18 fw-600 fc-blue">Invite People</div>
            </div>
            <div className="fs-12 fw-400 fc-black ">
              Like what the platform has to offer? Let your peers know about Crediwatch.
            </div>
          </div>
        </div>
        {getEmailUI()}
        {is_invite_enabled_func() &&
          <div className="d-flex justify-content-end mt-3">
            <div className="d-inline-block me-3">
              <button className="cw-btn cw-btn-link" onClick={handleClear}>
                Clear All
              </button>
            </div>
            <div className="d-inline-block">
              <button className="cw-btn cw-btn-primary" 
              onClick={() => {
                status?
                handleSubmit():dispatch(showModal())
              }}
              >
                Invite
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  profileData: _.get(state, "my_profile.profileData"),
});

export default connect(mapStateToProps)(InviteComponent);

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link,useHistory,useLocation } from "react-router-dom";
import getIcons from "../../helpers/getIcons";
import _ from "lodash";
import { connect } from "react-redux";
import {
  setEnableMobileMenu,
  setEnableClosedSidebar,
} from "../../reducers/ThemeOptions";
import PerfectScrollbar from "react-perfect-scrollbar";
import { appsidebar } from "../../utils/appsidebar";
import logout from "../../utils/logout";

const Nav = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const config = useSelector((state) => state.config.config);
  const sideBarItems = useSelector((state) => state.ThemeOptions.sideBarItems);
  const enableClosedSidebar = useSelector((state) => state.ThemeOptions.enableClosedSidebar);
  const enableMobileMenu = useSelector((state) => state.ThemeOptions.enableMobileMenu);

  const is_widget_disabled = (url) => {
    let current_policy_method = _.get(props, `policy.${url}.method`, []);
    if (_.isArray(current_policy_method) && current_policy_method.includes("GET")) {
      return true;
    } else {
      return false;
    }
  };
  
  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableClosedSidebar(!enableClosedSidebar));
  };

  const onSelection = (params) => {
    history.push(params.url);
    toggleMobileSidebar();
  };

  const converterFunc = () => {
    let arr = _.chain(sideBarItems)
      .map((menu) => {
        return {
          ...menu,
          onSelection: () => onSelection(menu),
          icon: getIcons({ icon: menu.icon }),
        };
      })
      .value();
    return arr;
  };

  const shouldRenderDiv = sideBarItems.some(
    (item) => item.label === "Parties" || item.label === "Users"
  );
  const nav_list = converterFunc();

  const [hover, setHover] = useState(false);
  const pathname = location.pathname;

  return (
    <>
      {shouldRenderDiv ? (
        <PerfectScrollbar className="h-100">
          <div
            data-tut="party-sidebar"
            className="app-sidebar__inner h-100 d-flex flex-column"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className="mt-2">
              {appsidebar.map((item, index) => {
               
                return (
                  <Link
                    key={index}
                    to={item.to}
                    onClick={toggleMobileSidebar}
                    className={`nav-link d-flex align-items-center p-2 ${is_widget_disabled(item.url) ? "font-weight-bold" : "opacity-50"} ${pathname === item.to ? "active-menu-item bg-lightBlue" : ""}`}
                  >
                    <i className={`icon ${item.icon} mx-2 `}></i>
                    {!enableClosedSidebar && <span>{item.label}</span>}
                    {enableClosedSidebar && hover && <span>{item.label}</span>}
                  </Link>
                );
              })}
              <span className="d-none d-md-inline">
                <i
                  className={`${enableClosedSidebar ? "pin" : "pin-primary"} ${!enableClosedSidebar ? "text-primary" : ""
                    } sidebar-closed bg-secondary-subtle pdf_padding fs-14 cursor-pointer`}
                  onClick={toggleEnableClosedSidebar}
                />
              </span>
            </div>
            <div style={{ marginTop: "270px" }}>
              <a
                onClick={() => logout(config)}
                className="nav-link d-flex align-items-center p-2 cursor-p"
              >
                <i className={`icon sign-out mx-2  `}></i>
                {!enableClosedSidebar && <span className="mb-2">Sign Out</span>}
                {enableClosedSidebar && hover && (
                  <span className="mb-2">Sign Out</span>
                )}
              </a>
            </div>
          </div>
        </PerfectScrollbar>
      ) : (
        <PerfectScrollbar className="h-100 ">
          <div
            data-tut="party-sidebar"
            className="app-sidebar__inner h-100 d-flex flex-column"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className="flex-grow-1 mt-2">
              {nav_list.map((item, index) => (
                <Link
                  key={index}
                  to={item.to}
                  onClick={toggleMobileSidebar}
                  className={`nav-link d-flex align-items-center p-2 ${is_widget_disabled(item.url) ? "font-weight-bold" : "opacity-50"} ${pathname === item.to && "active-menu-item bg-lightBlue"}`}
                >
                  <i className={`icon ${item.icon} mx-2`}></i>
                  {!enableClosedSidebar && <span>{item.label}</span>}
                  {enableClosedSidebar && hover && <span>{item.label}</span>}
                </Link>
              ))}
            </div>
            <span className="d-none d-md-inline">
              <i
                className={`${enableClosedSidebar ? "pin" : "pin-primary"} ${!enableClosedSidebar ? "text-primary" : ""
                  } sidebar-closed bg-secondary-subtle pdf_padding fs-14 cursor-pointer`}
                onClick={toggleEnableClosedSidebar}
              />
            </span>
          </div>
        </PerfectScrollbar>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  policy: _.get(state, "my_profile.profileData.policy")
});

export default connect(mapStateToProps)(withRouter(Nav));


import React, { PureComponent, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loader from "react-loaders";
import _ from "lodash";
import { connect } from "react-redux";

import allPaths from "./allPaths";
import PrivateRoute from "./PrivateRoute";
import Auth from "../pages/Auth";
import AmplifyLogin from "../pages/AmplifyLogin";
import ErrorPage from "../pages/Error";
import AccessDeniedPage from "../pages/AccessDenied";
import GA from "../components/GA";
import GTM from "../components/AppHelmet/GTM";
import ErrorPanel from "../components/ErrorPanel";
const Home = React.lazy(() => import("../pages/Home"));
const Admin = React.lazy(() => import("../pages/Admin"));
const Search = React.lazy(() => import("../pages/searchResults"));
const Party = React.lazy(() => import("../pages/Party"));
const JobDetail = React.lazy(() => import("../pages/Jobs/JobDetail"));
const Portfolios = React.lazy(() => import("../pages/Portfolios"));
const PortfolioDetails = React.lazy(() =>
  import("../pages/Portfolios/Details")
);
const Products = React.lazy(() => import("../pages/Products"));
const OrderDetails = React.lazy(() => import("../pages/Products/OrderDetails"));
const PDF = React.lazy(() => import("../pages/PDF"));
const MyParties = React.lazy(() => import("../pages/MyParties"));
const Landing = React.lazy(() => import("../pages/Landing"));
const MyBiz = React.lazy(() => import("../pages/MyBiz/index"));
const Macro_indi = React.lazy(() => import("../pages/Macro_indicators"));
const watchlist = React.lazy(() => import("../pages/Watchlist"));
const LegalBlob = React.lazy(() => import("../pages/LegalBlob"));
const Reports = React.lazy(() => import("../pages/Reports"));
const verifyBusiness = React.lazy(() =>
  import("../pages/VerifiedBusinessResult")
);
const MCAStatus = React.lazy(() => import("../pages/MCA"));
const Consent = React.lazy(() => import("../pages/Consent"));
const EqaroSetup = React.lazy(() => import("../pages/Eqaro-setup"));
const RupeeBossSetup = React.lazy(() => import("../pages/RupeeBoss-Setup"));
const QuickEligibility = React.lazy(() => import("../pages/Quick-Eligibility"));
const Cases = React.lazy(() => import("../pages/Case-Management"));
const Onboarding = React.lazy(() => import("../pages/OnboardingScreen"));
const MY_Pages = React.lazy(() => import("../pages/My-Pages"));
const MY_Alert = React.lazy(() => import("../pages/Alerts"));
const DocsComponents = React.lazy(() => import("../pages/Documentation/index"));
const SignalsDocs = React.lazy(() =>
  import("../pages/Documentation/Signals/index")
);
const Scores = React.lazy(() => import("../pages/Documentation/Scores/index"));
const ApiRef = React.lazy(() =>
  import("../pages/Documentation/ApiReference/index")
);
const ProductsGuide = React.lazy(() =>
  import("../pages/Documentation/ProductGuide/index")
);
const Approvals = React.lazy(() => import("../pages/Approvals"));
const Admin_Users = React.lazy(() => import("../pages/AdminUsers/index"));
const Parties = React.lazy(() => import("../pages/Parties"));
const MyAccount = React.lazy(() => import("../pages/MyAccount"));
const Jobs = React.lazy(() => import("../pages/Jobs"));
const OrgUnits = React.lazy(() => import("../pages/Org-Units"));
const Uploads = React.lazy(() => import("../pages/Uploads"));
const Zoho = React.lazy(() => import("../pages/Zoho"));
const Apps = React.lazy(() => import("../pages/Apps"));
const BulkExport = React.lazy(() => import("../pages/BulkExport"));
const Forms = React.lazy(() => import("../pages/Forms"));
const ShowCause=React.lazy(()=>import("../pages/ShowCause"))

const SuspenseHoc = (WrapComp, having_access = true) => {
  return (
    <Suspense
      fallback={
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loader type="line-scale" />
        </div>
      }
    >
      {having_access ? <WrapComp /> : <AccessDeniedPage />}
    </Suspense>
  );
};

class Routes extends PureComponent {
  render() {
    //const is_enterprise = _.get(this.props, "my_org.is_enterprise");
    const is_enterprise = true;
    const my_biz_available = _.get(this.props, "party_id", false);

    const GOOGLE_TAG_MANAGER =
      this.props.config && this.props.config.GOOGLE_TAG_MANAGER;
    const GOOGLE_ANALYTICS =
      this.props.config && this.props.config.GOOGLE_ANALYTICS;

    return (
      <>
        {GOOGLE_TAG_MANAGER && <GTM GOOGLE_TAG_MANAGER={GOOGLE_TAG_MANAGER} />}
        {GOOGLE_ANALYTICS && <GA />}
        <ErrorPanel/>
        <Switch>   
          <Route
            exact
            path={allPaths.LANDING_ROUTE}
            component={() => SuspenseHoc(Landing)}
          />
          <PrivateRoute
            exact
            path={allPaths.QUICK_CALCULATOR_APP}
            component={() => SuspenseHoc(QuickEligibility)}
          />
          <PrivateRoute
            exact
            path={allPaths.PRODUCT_DETAILS_ROUTE}
            component={() => SuspenseHoc(OrderDetails)}
          />
          <PrivateRoute
            path={allPaths.PRODUCTS_ROUTE}
            component={() => SuspenseHoc(Products)}
          />

          <PrivateRoute
            path={allPaths.REPORTS_ROUTE}
            component={() => SuspenseHoc(Reports)}
          />
          <PrivateRoute
            path={allPaths.HOME_ROUTE}
            component={() => SuspenseHoc(Home)}
          />
          <PrivateRoute
            path={allPaths.ADMIN_ROUTE}
            component={() => SuspenseHoc(Admin)}
          />
          <PrivateRoute
            path={allPaths.PARTY_ROUTE}
            component={() => SuspenseHoc(Party)}
          />
          <PrivateRoute
            exact
            path={allPaths.PORTFOLIOS_LIST_ROUTE}
            component={() => SuspenseHoc(Portfolios, is_enterprise)}
          />

          <PrivateRoute
            path={allPaths.PORTFOLIO_ROUTE}
            component={() => SuspenseHoc(PortfolioDetails, is_enterprise)}
          />

          <PrivateRoute
            path={allPaths.MACRO_INDICATOR}
            component={() => SuspenseHoc(Macro_indi, is_enterprise)}
          />
          <PrivateRoute
            path={allPaths.SEARCH_ROUTE}
            component={() => SuspenseHoc(Search)}
          />

          <PrivateRoute
            path={allPaths.PDF_ROUTE}
            component={() => SuspenseHoc(PDF)}
          />
          <PrivateRoute
            path={allPaths.LEGAL_BLOB_ROUTE}
            component={() => SuspenseHoc(LegalBlob)}
          />

          <PrivateRoute
            path={allPaths.MY_BIZ_ROUTE}
            component={() => SuspenseHoc(MyBiz)}
          />

          <PrivateRoute
            path={allPaths.WATCHLIST}
            component={() => SuspenseHoc(watchlist)}
          />

          <PrivateRoute
            path={allPaths.LEGAL_BLOB_ROUTE}
            component={() => SuspenseHoc(LegalBlob)}
          />

          <PrivateRoute
            path={allPaths.VERIFY_BUSINESS}
            component={() => SuspenseHoc(verifyBusiness)}
          />

          <PrivateRoute
            path={allPaths.MCA_STATUS}
            component={() => SuspenseHoc(MCAStatus)}
          />

          <PrivateRoute
            path={allPaths.EQARO_STEP_SETUP_ROUTE}
            component={() => SuspenseHoc(EqaroSetup)}
          />
          <PrivateRoute
            path={allPaths.RUBEE_BOSS_SETUP_ROUTE}
            component={() => SuspenseHoc(RupeeBossSetup)}
          />

          <PrivateRoute
            path={allPaths.CONSENT_ROUTE}
            component={() => SuspenseHoc(Consent)}
          />

          <PrivateRoute
            path={allPaths.CMM_ROUTE}
            component={() => SuspenseHoc(Cases)}
          />

          <PrivateRoute
            path={allPaths.ONBOARDING}
            component={() => SuspenseHoc(Onboarding)}
          />

          <PrivateRoute
            path={allPaths.MY_ROUTE}
            component={() => SuspenseHoc(MY_Pages)}
          />
          <PrivateRoute
            path={allPaths.MY_ALERT}
            component={() => SuspenseHoc(MY_Alert)}
          />

          <PrivateRoute
            path={allPaths.DOCS_ROUTE}
            component={() => SuspenseHoc(DocsComponents)}
          />

          <PrivateRoute
            path={allPaths.ADMIN_APPROVALS}
            component={() => SuspenseHoc(Approvals)}
          />
          <PrivateRoute
            path={allPaths.ADMIN_USER}
            component={() => SuspenseHoc(Admin_Users)}
          />
          <PrivateRoute
            path={allPaths.PARTIES}
            component={() => SuspenseHoc(Parties)}
          />
          <PrivateRoute
            path={allPaths.MY_ACCOUNT}
            component={() => SuspenseHoc(MyAccount)}
          />
          <PrivateRoute
            path={allPaths.ADMIN_JOBS}
            component={() => SuspenseHoc(Jobs)}
          />
          <PrivateRoute
            path={allPaths.ADMIN_JOB_ROUTE}
            component={() => SuspenseHoc(JobDetail)}
          />
          <PrivateRoute
            path={allPaths.ORG_UNITS}
            component={() => SuspenseHoc(OrgUnits)}
          />
          <PrivateRoute
            path={allPaths.ADMIN_UPLOADS}
            component={() => SuspenseHoc(Uploads)}
          />

          <PrivateRoute
            path={allPaths.APPS}
            component={() => SuspenseHoc(Apps)}
          />
          <PrivateRoute
            path={allPaths.ZOHO}
            component={() => SuspenseHoc(Zoho)}
          />
          <PrivateRoute
            path={allPaths.BULK_EXPORT}
            component={() => SuspenseHoc(BulkExport)}
          />
          <PrivateRoute
            path={allPaths.FORMS}
            component={() => SuspenseHoc(Forms)}
          />
           <PrivateRoute
            path={allPaths.SHOWCAUSE}
            component={() => SuspenseHoc(ShowCause)}
          />

          <Route path={allPaths.SET_TOKEN_ROUTE} component={AmplifyLogin} />
          <Route path={allPaths.AUTH_ROUTE} component={Auth} />
          <Route path={allPaths.ERROR_ROUTE} component={ErrorPage} />
          <Route render={() => <Redirect to={allPaths.LANDING_ROUTE} />} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    party_id: _.get(state.my_profile.my_org, "party_id"),
    my_org: _.get(state, "my_profile.my_org"),
    config: state.config.config,
  };
};

export default connect(mapStateToProps, null)(Routes);
